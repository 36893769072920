<template>
    <b-row class="rowbg">
        <b-col md="12">
            <b-card>
                <b-card-title>Missie</b-card-title>
                <b-card-text>
                    Overal waar Piet en Sint komen zorgen wij voor sociale verrassende creatieve interactie en maken het zo tot een gezellig familiefeest.
                </b-card-text>
            </b-card>
            <b-card>
                <b-card-title>Visie</b-card-title>
                <b-card-text>
                    <p><u>Plezier</u> voor iedereen is waar wij altijd mee bezig zijn. Wij zorgen voor een familiefeest. Niet alleen de kinderen maar ook de ouders, grootouders, werknemers en entertainers zijn een onderdeel van het feest. De groep entertainers genieten zichtbaar van wat ze doen en brengen dit enthousiasme vanzelf over.</p>
                    <p><u>Kwaliteit</u> is wat wij leveren. Wij besteden veel aandacht aan het opleiden van onze entertainers en zorgen ervoor dat de kostuums er keurig flitsend uitzien.</p>
                    <p><u>Ontzorgen</u> is wat wij doen. Alle lasten voor u als organisator nemen wij uit handen. Zo verzamelen wij de informatie voor in het Grote Boek, kunnen wij de technische ondersteuning verzorgen en hebben wij onze cadeauservice.</p>
                    <p><u>Piet en Sint</u> is wie we zijn. Wij spelen geen rol maar zijn Piet en Sint. Piet en Sint zijn een geoliede machine en blinken uit in interactie met het volledige publiek. Piet stuurt, danst en zingt en Sint doet gezellig mee en zorgt voor onvergetelijke één-op-één momenten. Het Sinterklaasfeest is een prachtige traditie die wij in ere mogen houden. Het is een familiefeest waarbij het gaat om gezelligheid. Wij geloven in Sinterklaas. Maar nog veel meer geloven wij in Piet & Sint.</p>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    export default {
        name: 'Missie'
    }
</script>

<style>

</style>